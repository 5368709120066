import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "radio", "selectionRank" ]

  select({ target }) {
    const selectedRadio = target;
    const selectedIndex = Array.from(this.radioTargets).indexOf(selectedRadio) + 1

    this.selectionRankTarget.value = selectedIndex
  }
}
