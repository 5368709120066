import { Controller } from "stimulus"
import { handleRequiredFields } from "./concerns/handle_required_fields";

export default class extends Controller {
  static targets =["hiddenFields"]

  toggle(event) {
    const $target = this.hiddenFieldsTargets.find((el) => el.dataset.for === event.target.name)
    if (!$target) return;

    $target.classList.toggle('hidden', !event.target.checked);

    handleRequiredFields($target);
  }

  connect() {
    Array.from(this.hiddenFieldsTargets).forEach(handleRequiredFields);
  }
}
